import React from "react";

import FetchData from "../../components/FetchData";
import fetchSavedCombinations, {SavedCombinations} from "../../api/fetchSavedCombinations";

export default function SavedSummary() {
    return (
        <div className="mt-4">
            <h3>Saved combinations</h3>
            <FetchData<{}, SavedCombinations>
                endpoint={fetchSavedCombinations}
                params={{}}
                deps={[]}
            >
                {(savedCombinations: SavedCombinations) => (
                    <React.Fragment>
                        {Object.keys(savedCombinations).map((uid: string) => (
                            <div className="my-5" key={uid}>
                                <div style={{fontWeight: "bold"}}>
                                    {uid}
                                </div>
                                {Object.keys(savedCombinations[uid]).map((projectId: string) => (
                                    <div className="ml-2 mt-2" key={projectId}>
                                        <div>
                                            Project: <b>{projectId}</b>
                                        </div>
                                        {Object.keys(savedCombinations[uid][projectId]).map((runId: string) => {
                                            const savedData = savedCombinations[uid][projectId][runId];
                                            return (
                                                <div className="ml-4 mt-2" key={runId}>
                                                    <div>
                                                        Run: <b>{runId}</b>
                                                    </div>
                                                    <div>Comment: {savedData.comment}</div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                ))}
                            </div>
                        ))}
                    </React.Fragment>
                )}
            </FetchData>
        </div>
    );
}
