import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {faHeart as faHeartOpen} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import flatMap from "lodash.flatmap";
import groupBy from "lodash.groupby";
import queryString from "query-string";
import React, {useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import AsyncSelect from "react-select/lib/Async";
import {
    Badge,
    Button,
    Input, Label,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Pagination, PaginationItem, PaginationLink,
    Table,
    Tooltip,
} from "reactstrap";
import debounce from "debounce-promise";

import {SavedCombinationProject} from "../../api/fetchSavedCombinations";
import saveCombinationApi from "../../api/saveCombination";
import searchMoa, {
    MechanismOfAction,
} from "../../api/searchMoa";
import searchDrug, {
    Drug,
} from "../../api/searchDrug";
import unsaveCombinationApi from "../../api/unsaveCombination";
import fetchRun, {
    FetchRunProps,
    Row,
    Run,
} from "../../api/fetchRun";
import useFetchData from "../../components/useFetchData";
import KnownListSelect from "../../components/KnownListSelect";
import Spinner from "../../components/Spinner";
import Error from "../../components/Error";
import {asArray, asBoolean, formatRunName, uuid, arrayToDict} from "../../utils";

import style from "./runViewer.module.scss";
import useUrlParams from "../../components/useUrlParams";

interface DrugFilter {
    [key: string]: Drug;
}

interface MoaFilter {
    [key: string]: MechanismOfAction,
}

interface RunViewerProps {
    runId: string;
}

function RunViewer(props: RouteComponentProps<RunViewerProps>) {
    const [urlParams, setUrlParams] = useUrlParams(props);

    // Process url params
    const runId = props.match.params.runId;
    const params = queryString.parse(props.location.search);
    const projectId: string = params.projectId as string;
    const datasetId: string = params.datasetId as string;
    const urlSavedOnly: boolean = asBoolean(urlParams.savedOnly as string);
    const urlCompounds = asArray(params.compound) as Array<string>;
    const urlCompoundsDict = arrayToDict(urlCompounds);
    const urlExcludeCompounds = asArray(params.exclude) as Array<string>;
    const urlExcludeCompoundsDict = arrayToDict(urlExcludeCompounds);
    const urlMoas = asArray(params.moa) as Array<string>;
    const urlMoasDict = arrayToDict(urlMoas);

    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(50);
    const [subset, setSubset] = useState<string>("All");
    const [nSafeDrugs, setNSafeDrugs] = useState<string>("Any");
    const [filterCompounds, setFilterCompounds] = useState<DrugFilter>(urlCompoundsDict);
    const [excludeCompounds, setExcludeCompounds] = useState<DrugFilter>(urlExcludeCompoundsDict);
    const [filterMoas, setFilterMoas] = useState<MoaFilter>(urlMoasDict);
    const [list, setList] = useState<string | null>(null);
    const [savedOnly, setSavedOnly] = useState<boolean>(urlSavedOnly);
    const [combinationToSave, setCombinationToSave] = useState<Row | null>(null);
    const [savedCombinations, setSavedCombinations] = useState<number>(0);
    const [saving, setSaving] = useState<boolean>(false);
    const [showSafetyInfo, setShowSafetyInfo] = useState<boolean>(true);
    const [showMoaInfo, setShowMoaInfo] = useState<boolean>(false);

    const filterCompoundsList = Object.keys(filterCompounds).map(drugName => filterCompounds[drugName]);
    const excludeCompoundsList = Object.keys(excludeCompounds).map(drugName => excludeCompounds[drugName]);
    const filterMoasList = Object.keys(filterMoas).map(moa => filterMoas[moa]);

    const changeSavedOnly = (option: boolean) => {
        setUrlParams({
            savedOnly: option ? "true" : "false",
        });
        setSavedOnly(option);
    };

    const fetchRunParams = {
        runId,
        projectId,
        knownList: list,
        pageNumber,
        pageSize,
        drugsSearch: filterCompoundsList,
        drugsExclude: excludeCompoundsList,
        subsetChoice: subset,
        nSafeDrugs,
        savedOnly,
        moas: filterMoasList,
    };

    const fetchRunDeps = [
        pageNumber,
        pageSize,
        filterCompounds,
        excludeCompounds,
        filterMoas,
        subset,
        nSafeDrugs,
        list,
        savedOnly,
        savedCombinations,
    ];

    const [run, loading, error] = useFetchData<FetchRunProps, Run>(
        fetchRun,
        fetchRunParams,
        fetchRunDeps,
    );

    if (error !== null) {
        return <Error message="Problem fetching data"/>
    }

    const rows = run == null ? [] : run.rows;
    const totalRows = run == null ? 0 : run.totalRows;

    const addCompoundFilter = (compound: Drug) => {
        if (compound) {
            const newFilterCompounds = {
                ...filterCompounds,
                [compound.name]: compound,
            };
            setFilterCompounds(newFilterCompounds);
            setUrlParams({
                compound: Object.keys(newFilterCompounds),
            });
        }
    };

    const addCompoundExclude = (compound: Drug) => {
        if (compound) {
            const newExcludeCompounds = {
                ...excludeCompounds,
                [compound.name]: compound,
            };
            setExcludeCompounds(newExcludeCompounds);
            setUrlParams({
                exclude: Object.keys(newExcludeCompounds),
            });
        }
    };

    const addMoaFilter = (moa: MechanismOfAction) => {
        const newMoas = {
            ...filterMoas,
            [moa.name]: moa,
        };
        if (moa) {
            setFilterMoas(newMoas);
            setUrlParams({
                moa: Object.keys(newMoas),
            });
        }
    };

    const removeCompoundFilter = (compound: Drug) => {
        const {[compound.name]: removed, ...otherCompoundFilters} = filterCompounds;
        setFilterCompounds(otherCompoundFilters);
        setUrlParams({
            compound: Object.keys(otherCompoundFilters),
        });
    };

    const removeCompoundExclude = (compound: Drug) => {
        const {[compound.name]: removed, ...otherExcludedCompounds} = excludeCompounds;
        setExcludeCompounds(otherExcludedCompounds);
        setUrlParams({
            compound: Object.keys(otherExcludedCompounds),
        });

    };

    const removeMoaFilter = (moa: MechanismOfAction) => {
        const {[moa.name]: removed, ...otherMoaFilters} = filterMoas;
        setFilterMoas(otherMoaFilters);
        setUrlParams({
            moa: Object.keys(otherMoaFilters),
        });
    };

    const previousPage = () => setPageNumber(pageNumber - 1);
    const nextPage = () => setPageNumber(pageNumber + 1);

    const saveCombination = (comment: string) => {
        if (combinationToSave !== null) {
            setSaving(true);
            const payload = {
                uid: combinationToSave.uid,
                projectId,
                runId,
                comment,
            };
            saveCombinationApi(payload)
                .then(() => {
                    setSaving(false);
                    setCombinationToSave(null);
                    setSavedCombinations(savedCombinations + 1);
                });
        }
    };

    const unsaveCombination = () => {
        if (combinationToSave !== null) {
            setSaving(true);
            const params = {
                uid: combinationToSave.uid,
                projectId,
                runId,
            };
            unsaveCombinationApi(params)
                .then(() => {
                    setSaving(false);
                    setCombinationToSave(null);
                    setSavedCombinations(savedCombinations - 1);
                });
        }
    };

    const defaultCommentForCombination = () => {
        if (combinationToSave === null) {
            return ""
        }
        if (combinationToSave.saved == null) {
            return "";
        }
        if (combinationToSave.saved[projectId] == null) {
            return "";
        }
        if (combinationToSave.saved[projectId][runId] == null) {
            return "";
        }
        return combinationToSave.saved[projectId][runId].comment;
    };

    const combinationIsSaved = () => {
        if (combinationToSave === null) {
            return false;
        }
        if (combinationToSave.saved == null) {
            return false;
        }
        if (combinationToSave.saved[projectId] == null) {
            return false;
        }
        return true;
    };

    const saveCombinationElement = combinationToSave ? (
        <SaveCombination
            combination={combinationToSave}
            currentComment={defaultCommentForCombination()}
            onCancel={() => setCombinationToSave(null)}
            onSave={(comment: string) => saveCombination(comment)}
            saving={saving}
            isSaved={combinationIsSaved()}
            onUnSaveCombination={() => unsaveCombination()}
        />
    ) : null;

    const tableDisplay = loading ? (<Spinner loading={loading}/>) : (
        <React.Fragment>
            {saveCombinationElement}
            <Table className="table-hover mt-2">
                <thead>
                <tr>
                    <th>Rank</th>
                    <th>Score</th>
                    <th>Drug 1</th>
                    <th>Drug 2</th>
                    <th>Percentile</th>
                    <th>N known</th>
                    <th>DDI</th>
                    <th>NLP link</th>
                    <th>Saved</th>
                </tr>
                </thead>
                <tbody>
                {rows.map((row: Row) => (
                    <DataRow
                        key={row.uid}
                        row={row}
                        onSave={() => setCombinationToSave(row)}
                        projectId={projectId}
                        showSafetyInfo={showSafetyInfo}
                        showMoaInfo={showMoaInfo}
                        knownList={list || ""}
                    />
                ))}
                </tbody>
            </Table>
        </React.Fragment>
    );

    const setKnownList = (list: string | null) => {
        if (list === null) {
            setSubset("All");
        }
        setList(list);
    };

    const theoreticalFrom = (pageNumber * pageSize) + 1;
    const from = totalRows < theoreticalFrom ? totalRows : theoreticalFrom;
    const theoreticalTo = (pageNumber * pageSize) + pageSize;
    const to = totalRows < theoreticalTo ? totalRows : theoreticalTo;

    return (
        <React.Fragment>
            <div className="my-4 px-4 d-flex flex-wrap flex-row justify-content-between">
                <div className="flex-column">
                    <span className={style.sectionTitle}>run</span>{' '}
                    <span style={{fontSize: "1.4em"}}>{formatRunName(runId)}</span>
                </div>
                <div className="flex-column">
                    <span className={style.sectionTitle}>dataset</span>{' '}
                    <span style={{fontSize: "1.4em"}}>{datasetId}</span>
                </div>
                <div className="flex-column">
                    <span className={style.sectionTitle}>project</span>{' '}
                    <span style={{fontSize: "1.4em"}}>{projectId}</span>
                </div>
            </div>
            <div className="mt-3">
                <div className={`${style.filter} ${style.withWidth}`}>
                    <KnownListSelect
                        projectId={projectId}
                        onSelectedList={setKnownList}
                    />
                </div>
                {list && (
                    <div className={`${style.filter} ${style.withWidth}`}>
                        <CombinationSubsetFilter
                            value={subset}
                            onChange={setSubset}
                            disabled={list === null}
                        />
                    </div>
                )}
            </div>
            <div className="mt-2">
                <div className="my-2">
                    <div className={`${style.filter} ${style.withWidth}`}>
                        <SafeDrugFilter value={nSafeDrugs} onChange={setNSafeDrugs}/>
                    </div>
                    <div className={`${style.filter} ${style.withWidth}`}>
                        <Label>Filter by compound name</Label>
                        <DrugSearch
                            runId={runId}
                            onInputChange={addCompoundFilter}
                            isDisabled={filterCompoundsList.length > 1}
                        />
                    </div>
                    <div className={`${style.filter} ${style.withWidth}`}>
                        <Label>Exclude compound</Label>
                        <DrugSearch
                            runId={runId}
                            onInputChange={addCompoundExclude}
                        />
                    </div>
                    <div className={`${style.filter} ${style.withWidth}`}>
                        <Label>Filter by mechanism of action</Label>
                        <MoaSearch
                            runId={runId}
                            onInputChange={addMoaFilter}
                        />
                    </div>
                    <SavedCombinationsFilter savedOnly={savedOnly} onChange={changeSavedOnly}/>

                    <ShowSafetyInfo showSafetyInfo={showSafetyInfo} onChange={setShowSafetyInfo}/>
                    <ShowMoaInfo showMoaInfo={showMoaInfo} onChange={setShowMoaInfo}/>

                    <div className="mt-4">
                        <CompoundFilterList
                            compounds={filterCompoundsList}
                            onRemove={removeCompoundFilter}
                            excluded={false}
                        />
                        <CompoundFilterList
                            compounds={excludeCompoundsList}
                            onRemove={removeCompoundExclude}
                            excluded={true}
                        />
                    </div>

                    <div className="mt-4">
                        <MoaFilterList
                            moas={filterMoasList}
                            onRemove={removeMoaFilter}
                        />
                    </div>

                </div>

                <div className="float-right">
                    Showing combinations{" "}
                    <b>{from}</b>{" "}
                    to{" "}
                    <b>{to}</b>{" "}
                    out of{" "}
                    <b>{totalRows}</b>
                </div>

                {tableDisplay}

                <Pagination className="float-right">
                    <PaginationItem>
                        <PaginationLink disabled={pageNumber === 0} onClick={() => previousPage()}>
                            Previous
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink
                            disabled={to === totalRows}
                            onClick={() => nextPage()}
                        >
                            Next
                        </PaginationLink>
                    </PaginationItem>
                </Pagination>

                <div style={{maxWidth: "150px"}}>
                    <Label for="rowsPerPage">Rows per page</Label>
                    <Input type="select" name="rowsPerPage" onChange={event => setPageSize(+event.target.value)}>
                        <option>50</option>
                        <option>100</option>
                        <option>150</option>
                        <option>200</option>
                    </Input>
                </div>
            </div>
        </React.Fragment>
    );
}

export default RunViewer;


interface FilterItemProps {
    compound: Drug;
    onRemove: (compound: Drug) => void;
    excluded: boolean;
}

function FilterItem({compound, onRemove, excluded}: FilterItemProps) {
    const filterClassName = classNames(style.compoundFilter, {
        "bg-success": !excluded,
        "bg-danger": excluded,
    });
    return (
        <div className={filterClassName} onClick={() => onRemove(compound)}>
            {compound.name}
        </div>
    )
}


interface CompoundListProps {
    compounds: Array<Drug>;
    onRemove: (compound: Drug) => void;
    excluded: boolean;
}

function CompoundFilterList({compounds, ...params}: CompoundListProps) {
    return (
        <span>
            {compounds.map(compound => (
                <FilterItem
                    key={compound.name}
                    compound={compound}
                    {...params}
                />
            ))}
        </span>
    )
}

interface MoaFilterListProps {
    moas: Array<MechanismOfAction>;
    onRemove: (moa: MechanismOfAction) => void;
}

function MoaFilterList({moas, ...params}: MoaFilterListProps) {
    return (
        <span>
            {moas.map(moa => (
                <FilterItem
                    key={moa.name}
                    compound={moa}
                    excluded={false}
                    {...params}
                />
            ))}
        </span>
    )
}


interface DataRowProps {
    row: Row;
    onSave: () => void;
    projectId: string;
    showSafetyInfo: boolean;
    showMoaInfo: boolean;
    knownList: string;
}

function DataRow({row, onSave, projectId, showSafetyInfo, showMoaInfo, knownList}: DataRowProps) {
    const [otherSavedModalOpen, setOtherSavedModalOpen] = useState<boolean>(false);

    const savedIcon = row.saved && row.saved[projectId] ? (
        <FontAwesomeIcon style={{cursor: "pointer"}} className="text-success mr-2" icon={faHeart}/>
    ) : (
        <FontAwesomeIcon style={{cursor: "pointer"}} className="text-muted mr-2" icon={faHeartOpen}/>
    );
    const projectsSavedCombination = row.saved ? Object.keys(row.saved) : [];
    const otherProjectsSavedCombination = projectsSavedCombination.filter(project => project !== projectId);

    const otherSavedIcon = otherProjectsSavedCombination.length > 0 ? (
        <span>
            {otherProjectsSavedCombination.length > 1 ? otherProjectsSavedCombination.length : ""}
            <FontAwesomeIcon style={{cursor: "pointer"}} className="text-warning" icon={faHeart}/>
        </span>
    ) : null;

    const drug1CellColor = (row.drug1IsSafe && showSafetyInfo) ? "#C4E7CC" : "transparent";
    const drug2CellColor = (row.drug2IsSafe && showSafetyInfo) ? "#C4E7CC" : "transparent";

    const knownListCellInfo = knownList ? row.nKnown[knownList] : "N/A";

    return (
        <React.Fragment>
            <tr>
                <td>{row.rank}</td>
                <td>{row.score}</td>
                <td style={{backgroundColor: drug1CellColor}}>
                    <DrugAttributes uid={uuid()} name={row.drug1} moa={showMoaInfo ? row.drug1Moa : null}/>
                </td>
                <td style={{backgroundColor: drug2CellColor}}>
                    <DrugAttributes uid={uuid()} name={row.drug2} moa={showMoaInfo ? row.drug2Moa : null}/>
                </td>
                <td>{row.percentile}</td>
                <td>{knownListCellInfo}</td>
                <td>{row.ddi}</td>
                <td>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`https://nlp.healx.io/#/cooccurrences?entity1=${row.drug1}&entity2=${row.drug2}&entity1_linked=1&entity2_linked=1&scope=document`}
                    >
                        NLP

                    </a>
                </td>
                <td>
                    <div>
                        <span onClick={() => onSave()}>
                            {savedIcon}
                        </span>
                        <span onClick={() => setOtherSavedModalOpen(true)}>
                            {otherSavedIcon}
                        </span>
                    </div>
                </td>
            </tr>
            <Modal isOpen={otherSavedModalOpen}>
                <ModalHeader>
                    {row.drug1} - {row.drug2}
                </ModalHeader>
                <ModalBody>
                    {row.saved ? (
                        <ShowSaved saved={row.saved}/>
                    ) : null}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setOtherSavedModalOpen(false)}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

interface DrugAttributesProps {
    uid: string;
    name: string;
    moa: Array<MechanismOfAction> | null;
}

function DrugAttributes({uid, name, moa}: DrugAttributesProps) {
    const [showMoaTooltip, setShowMoaTooltip] = useState<boolean>(false);

    const moaBadge = (moa !== null && moa.length > 0) ? (
        <div>
            <Badge
                style={{cursor: "default"}}
                id={uid}
                color="light"
                onMouseEnter={() => setShowMoaTooltip(true)}
                onMouseLeave={() => setShowMoaTooltip(false)}
            >
                Mechanisms of action: {moa == null ? 0 : moa.length}
            </Badge>
            <Tooltip
                placement="auto"
                isOpen={showMoaTooltip}
                target={uid}
                innerClassName={style.moaTooltip}
                autohide={false}
            >
                <ul className="mt-2">
                    {moa.map(mec => (
                        <li key={mec.name}>
                            {mec.name}
                        </li>
                    ))}
                </ul>
            </Tooltip>
        </div>
    ) : null;

    return (
        <React.Fragment>
            {name}
            {moaBadge}
        </React.Fragment>
    );
}

interface ShowSavedProps {
    saved: SavedCombinationProject;
}

function ShowSaved({saved}: ShowSavedProps) {
    const savedList = flatMap(
        Object.keys(saved),
        project => flatMap(
            Object.keys(saved[project]),
            run => saved[project][run]
        )
    );
    const savedGrouped = groupBy(savedList, combination => combination.projectId);
    return (
        <div>
            {Object.keys(savedGrouped).map(project => (
                <div className="my-4 border-light" key={project}>
                    <div className="mb-2">
                        Project: <b>{project}</b>
                    </div>
                    {savedGrouped[project].map(savedItem => (
                        <div className="mb-2 ml-4" key={savedItem.runId}>
                            <div>
                                Run: <b>{formatRunName(savedItem.runId)}</b>
                            </div>
                            <div>
                                {savedItem.comment}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
}


interface DrugSearchBoxProps {
    runId: string;
    onInputChange: (value: Drug) => void;
    isDisabled?: boolean;
}

function DrugSearch({isDisabled, onInputChange, runId}: DrugSearchBoxProps) {
    const [inputValue] = useState<Drug | null>(null);

    const loadOptions = (inputValue: string) => searchDrug({runId, query: inputValue}, null);

    const formatOptionLabel = (value: Drug) => value.name;

    const onChange = (value: any) => onInputChange(value);

    return (
        <AsyncSelect
            value={inputValue}
            loadOptions={loadOptions}
            isClearable
            onChange={onChange}
            placeholder="Search for a compound"
            formatOptionLabel={formatOptionLabel}
            isDisabled={isDisabled}
        />
    );
}

interface MoaSearchBoxProps {
    runId: string;
    onInputChange: (value: MechanismOfAction) => void;
}


function MoaSearch({onInputChange, runId}: MoaSearchBoxProps) {
    const [inputValue] = useState<MechanismOfAction | null>(null);

    const loadOptions = async (inputValue: string, callback: ((options: Array<MechanismOfAction>) => void)) => {
        const result = await searchMoa({runId, query: inputValue}, null);
        callback(result);
    };

    const debouncedLoadOptions = debounce(loadOptions, 500, {
        leading: true,
    });

    const formatOptionLabel = (value: MechanismOfAction) => value.name;

    const onChange = (value: any) => onInputChange(value);

    return (
        <AsyncSelect
            value={inputValue}
            loadOptions={debouncedLoadOptions}
            isClearable
            onChange={onChange}
            placeholder="Search for a mechanism of action"
            formatOptionLabel={formatOptionLabel}
        />
    );
}

interface CombinationSubsetFilterProps {
    value: string;
    onChange: (value: string) => void;
    disabled: boolean
}

function CombinationSubsetFilter({value, onChange, disabled}: CombinationSubsetFilterProps) {
    return (
        <React.Fragment>
            <Label for="setSubsetChoice">List subset</Label>
            <Input
                disabled={disabled}
                value={value}
                type="select"
                name="combiSubset"
                onChange={event => onChange(event.target.value)}
            >
                <option>All</option>
                <option>All_vs_Known</option>
                <option>Known_vs_Known</option>
            </Input>
        </React.Fragment>
    )
}

interface SafeDrugFilterProps {
    value: string;
    onChange: (value: string) => void;
}

function SafeDrugFilter({value, onChange}: SafeDrugFilterProps) {
    return (
        <React.Fragment>
            <Label for="setNSafeDrugs">Safe drugs</Label>
            <Input
                value={value}
                type="select"
                name="nSafeDrugs"
                onChange={event => onChange(event.target.value)}
            >
                <option>Any</option>
                <option>1</option>
                <option>2</option>
            </Input>
        </React.Fragment>

    );
}

interface ShowSafetyInfoProps {
    showSafetyInfo: boolean;
    onChange: (value: boolean) => void;
}

function ShowSafetyInfo({showSafetyInfo, onChange}: ShowSafetyInfoProps) {
    return (
        <div className={style.filter}>
            <Label value>
                <Input checked={showSafetyInfo} onChange={() => onChange(!showSafetyInfo)} type="checkbox"/>{' '}
                Highlight safe drugs
            </Label>
        </div>
    );
}

interface ShowMoaInfoProps {
    showMoaInfo: boolean;
    onChange: (value: boolean) => void;
}

function ShowMoaInfo({showMoaInfo, onChange}: ShowMoaInfoProps) {
    return (
        <div className={style.filter}>
            <Label value>
                <Input checked={showMoaInfo} onChange={() => onChange(!showMoaInfo)} type="checkbox"/>{' '}
                Show mechanisms of action
            </Label>
        </div>
    );
}

interface SavedCombinationsFilterProps {
    savedOnly: boolean;
    onChange: (value: boolean) => void;
}

function SavedCombinationsFilter({savedOnly, onChange}: SavedCombinationsFilterProps) {
    return (
        <div className={style.filter}>
            <Label value>
                <Input checked={savedOnly} onChange={() => onChange(!savedOnly)} type="checkbox"/>{' '}
                Show only saved combinations
            </Label>
        </div>
    );
}


interface SaveCombinationProps {
    combination: Row;
    currentComment: string;
    onCancel: () => void;
    onSave: (comment: string) => void;
    saving: boolean;
    isSaved: boolean;
    onUnSaveCombination: () => void;
}

function SaveCombination(
    {combination, currentComment, onCancel, isSaved, onSave, saving, onUnSaveCombination}: SaveCombinationProps) {
    const [comment, setComment] = useState<string>("");

    const modalBody = saving ? (
        <Spinner loading={true}>
            <div style={{minHeight: "50px"}}/>
        </Spinner>
    ) : (
        <ModalBody>
            <Input
                type="textarea"
                placeholder="Comment"
                onChange={event => setComment(event.target.value)}
                defaultValue={currentComment}
            />
        </ModalBody>
    );

    const unSaveButton = isSaved ? (
        <Button onClick={() => onUnSaveCombination()} color="danger">Unsave</Button>
    ) : null;

    return (
        <Modal isOpen={combination !== null}>
            <ModalHeader>{combination.drug1} - {combination.drug2}</ModalHeader>
            {modalBody}
            <ModalFooter>
                {unSaveButton}
                <Button onClick={() => onCancel()} color="secondary">Cancel</Button>
                <Button onClick={() => onSave(comment)} color="primary">Save</Button>
            </ModalFooter>
        </Modal>
    );
}
