import React, {useEffect, useState} from "react";
import Select from "react-select";

import fetchKnownListsForProject, {KnownList} from "../../api/fetchKnownListsForProject";

interface KnownListSelectProps {
    projectId: string;
    onSelectedList: (list: string) => void;
}

interface SelectOption {
    label: string;
    value: string;
}

export default function KnownListSelect({projectId, onSelectedList}: KnownListSelectProps) {
    const [knownLists, setKnownLists] = useState<Array<KnownList>>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [listOption, setListOption] = useState<SelectOption | null>(null);

    useEffect(() => {
        setIsLoading(true);
        fetchKnownListsForProject({projectId}, null)
            .then(response => {
                setKnownLists(response);
                setIsLoading(false);
            })
    }, []);

    const formatOption = (list: KnownList | undefined): SelectOption | null => {
        if (list == null) {
            return null;
        }
        return {
            label: list.name,
            value: list.name
        };
    };


    const onChange = (option: any) => {
        onSelectedList(option ? option.value : option);
        setListOption(option);
    };

    return (
        <React.Fragment>
            <div>Known compounds list:</div>
            <Select
                value={listOption}
                isDisabled={false}
                isClearable={true}
                isLoading={isLoading}
                options={knownLists.map(formatOption)}
                onChange={onChange}
            />
        </React.Fragment>
    );
}
