import React, {useState} from "react";
import GoogleLogin, {GoogleUser} from "react-google-login";
import {HashRouter as Router, Route} from "react-router-dom";

import Masthead from "../components/Masthead";
import Home from "../pages/Home";
import RunViewer from "../pages/RunViewer";
import ProjectSummary from "../pages/ProjectSummary";
import SavedSummary from "../pages/SavedSummary";

const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const ENABLE_AUTH = process.env.REACT_APP_ENABLE_AUTH !== "0";

function App(props: any) {
    const [user, setUser] = useState<GoogleUser | null>(null);

    const loginSuccess = (googleUser: GoogleUser) => {
        if(googleUser.profileObj.email.endsWith("@healx.io")) {
            setUser(googleUser);
        } else {
            console.log("Authentication failed!!");
            // TODO: Add 403 page and redirect from here
        }
    };

    const loginFailure = () => {
        console.log("Login failed!!!");
    };

    const app = (user === null && ENABLE_AUTH) ? (
        <GoogleLogin
            className="mx-3 my-3"
            clientId={GOOGLE_CLIENT_ID}
            buttonText="Login with Google"
            onSuccess={loginSuccess}
            onFailure={loginFailure}
        />
    ) : (
        <div className="container-fluid">
            <main>
                <Route exact path="/" component={Home}/>
                <Route path="/run/:runId" component={RunViewer}/>
                <Route path="/project/:projectId" component={ProjectSummary}/>
                <Route path="/saved" component={SavedSummary}/>
            </main>
        </div>
    );

    return (
        <div className="App">
            <Router {...props}>
                <div>
                    <Masthead user={user} onLogoutSuccess={() => setUser(null)}/>
                </div>
                {app}
            </Router>
        </div>
    );
}

export default App;
