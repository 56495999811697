import classNames from "classnames";
import flatMap from "lodash.flatmap";
import React, {useState} from "react";
import {RouteComponentProps} from "react-router-dom";
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import queryString from "query-string";

import fetchProjectSummary, {
    FetchProjectSummaryParamsProps,
    ProjectCombinationSummary,
    ProjectDrugSummary,
    ProjectMoASummary,
    ProjectRunSummary,
    FetchProjectSummary,
} from "../../api/fetchProjectSummary";
import FetchData from "../../components/FetchData";
import ScoreSummaryDotPlot, {ScoreSummaryList} from "../../components/ScoreSummaryPlot";

import style from "./projectSummary.module.scss";

interface ProjectSummaryProps {
    projectId: string;
}

enum tabName {
    "drugs",
    "combinations",
    "moas",
}

function ProjectSummaryViewer(props: RouteComponentProps<ProjectSummaryProps>) {
    const projectId = props.match.params.projectId;

    const [activeTab, setActiveTab] = useState<tabName>(tabName.drugs);

    const toggleTab = (tab: tabName) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    return (
        <FetchData<FetchProjectSummaryParamsProps, FetchProjectSummary>
            endpoint={fetchProjectSummary}
            params={{projectId}}
            deps={[projectId]}
        >{(summary: FetchProjectSummary) => {
            const drugsSummary = summary ? (
                <DrugsSummaryDisplay projectId={projectId} drugs={summary.drugs}/>
            ) : null;

            const combinationsSummary = summary ? (
                <CombinationsSummaryDisplay projectId={projectId} combinations={summary.combinations}/>
            ) : null;

            const moasSummary = summary ? (
                <MoAsSummaryDisplay projectId={projectId} moas={summary.moas}/>
            ) : null;

            return (
                <div className="mt-3">
                    <div className="my-4">
                        <h3>
                            <span className={style.sectionTitle}>project</span>
                            <span style={{fontSize: "1.5em"}}>{projectId}</span>
                        </h3>
                    </div>
                    <Nav tabs>
                        <NavItem>
                            <NavLink
                                className={classNames({active: activeTab === tabName.drugs})}
                                onClick={() => toggleTab(tabName.drugs)}
                            >
                                Drugs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames({active: activeTab === tabName.combinations})}
                                onClick={() => toggleTab(tabName.combinations)}
                            >
                                Combinations
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classNames({active: activeTab === tabName.moas})}
                                onClick={() => toggleTab(tabName.moas)}
                            >
                                Mechanisms of action
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        <TabPane tabId={tabName.drugs}>
                            {drugsSummary}
                        </TabPane>
                        <TabPane tabId={tabName.combinations}>
                            {combinationsSummary}
                        </TabPane>
                        <TabPane tabId={tabName.moas}>
                            {moasSummary}
                        </TabPane>
                    </TabContent>
                </div>
            );
        }}
        </FetchData>
    );
}

export default ProjectSummaryViewer;

interface CombinationsSummaryProps {
    projectId: string;
    combinations: Array<ProjectCombinationSummary>;
}

function CombinationsSummaryDisplay({projectId, combinations}: CombinationsSummaryProps) {
    const items = combinations.map(combination => ({
        label: combination.drugs.join("-"),
        values: summaryValues(combination.datasetPercentiles),
        urlParams: queryString.stringify({projectId, compound: combination.drugs})
    }));

    return (
        <div className="mt-4">
            <h3 className="mt-5">Plot</h3>
            <Row>
                <Col sm={{size: 12}} xl={{size: 8, offset: 2}}>
                    <ScoreSummaryDotPlot items={items}/>
                </Col>
            </Row>
            <h3 className="mt-5">List</h3>
            {combinations.map(combination => (
                <React.Fragment key={combination.uid}>
                    <div className={style.label}>{combination.drugs.join(", ")}</div>
                    <ScoreSummaryList
                        values={summaryValues(combination.datasetPercentiles)}
                        score={combination.score}
                        urlParams={queryString.stringify({projectId, compound: combination.drugs})}
                    />
                </React.Fragment>
            ))}
        </div>
    );
}

interface DrugsSummaryProps {
    projectId: string;
    drugs: Array<ProjectDrugSummary>;
}

function DrugsSummaryDisplay({projectId, drugs}: DrugsSummaryProps) {

    const items = drugs.map(drug => ({
        label: drug.drugName,
        values: summaryDrugValues(drug),
        urlParams: queryString.stringify({projectId, compound: drug.drugName}),
    }));

    return (
        <div className="mt-4">
            <h3 className="mt-5">Plot</h3>
            <Row>
                <Col sm={{size: 12}} xl={{size: 8, offset: 2}}>
                    <ScoreSummaryDotPlot items={items}/>
                </Col>
            </Row>
            <h3 className="mt-5">List</h3>
            {drugs.map(drug => (
                <React.Fragment key={drug.drugName}>
                    <div className={style.label}>{drug.drugName}</div>
                    <ScoreSummaryList
                        values={summaryDrugValues(drug)}
                        score={drug.score}
                        urlParams={queryString.stringify({projectId, compound: drug.drugName})}
                    />
                </React.Fragment>
            ))}
        </div>
    );
}

interface MoAsSummaryDisplayProps {
    projectId: string;
    moas: Array<ProjectMoASummary>;
}

function MoAsSummaryDisplay({moas, projectId}: MoAsSummaryDisplayProps) {
    const items = moas.map(moa => ({
        label: moa.moas.join("-"),
        values: summaryDrugValues(moa),
        urlParams: queryString.stringify({projectId, moa: moa.moas}),
    }));

    return (
        <div className="mt-4">
            <h3 className="mt-5">Plot</h3>
            <Row>
                <Col sm={{size: 12}} xl={{size: 8, offset: 2}}>
                    <ScoreSummaryDotPlot items={items}/>
                </Col>
            </Row>
            <h3 className="mt-5">List</h3>

            {moas.map(moa => (
                <div className="mb-3" key={moa.uid}>
                    <div className={style.label}>
                        {moa.moas.join("-")}
                    </div>
                    <ScoreSummaryList
                        values={summaryDrugValues(moa)}
                        score={moa.score}
                        urlParams={queryString.stringify({projectId, moa: moa.moas})}
                    />
                </div>
            ))}
        </div>
    )
}

interface DotValue {
    run: string;
    score: number;
    dataset: string;
}

function summaryValues(items: Array<DotValue>) {
    return items.map(item => ({
        runId: item.run,
        score: item.score,
        dataset: item.dataset,
    }));
}


interface ValuesInterface {
    runs: Array<ProjectRunSummary>;
    dataset: string;
}

interface DrugValuesInterface {
    datasets: Array<ValuesInterface>;
}

function summaryDrugValues(drug: DrugValuesInterface) {
    return flatMap(drug.datasets, dataset => summaryValues(
        dataset.runs.map(run => ({
            ...run,
            dataset: dataset.dataset,
        }))
    ));
}
