import React, {Fragment} from "react";
import {SizeMe, SizeMeProps} from "react-sizeme";
import {PulseLoader, ReactSpinners} from "react-spinners";

interface SpinnerProps extends React.HTMLAttributes<HTMLElement> {
    loading: boolean;
    spinnerParams?: ReactSpinners.PulseLoaderProps;
}

function Spinner({loading, style, children, spinnerParams}: SpinnerProps) {
    if (!loading) {
        return (
            <div>
                {children}
            </div>
        );
    }

    return (
        <div style={{position: "relative"}}>
            <SizeMe monitorHeight>
                {(containerSize: SizeMeProps) => (
                    <Fragment>
                        <div>
                            {children}
                        </div>
                        <RenderSpinner
                            style={style}
                            containerSize={containerSize}
                            loading={loading}
                            {...spinnerParams}
                        />
                    </Fragment>
                )}
            </SizeMe>
        </div>
    );
}

interface RenderSpinnerProps {
    containerSize: SizeMeProps;
    style: React.CSSProperties | undefined;
    loading: boolean;
}

function RenderSpinner({containerSize, style, loading, ...spinnerParams}: RenderSpinnerProps) {
    return (
        <div
            style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0.9,
                display: loading ? "block" : "none",
                ...style,
            }}
        >
            <div
                className="text-center align-middle"
                style={{
                    position: "absolute",
                    top: `${((containerSize.size.height || 50) / 2) - 8}px`,
                    left: `${((containerSize.size.width || 1) / 2) - 25}px`,
                }}
            >
                <PulseLoader
                    sizeUnit={"px"}
                    size={15}
                    color={"#66C1B0"}
                    loading={loading}
                    {...spinnerParams}
                />
            </div>
        </div>
    );
}

export default Spinner;
