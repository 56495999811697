import {useState, useEffect, useRef} from 'react';

export type FetchData<ParamsType, DataType> = (params: ParamsType, signal: AbortSignal) => Promise<DataType>;

export default function useFetchData<ParamsType, DataType>(
    fetchData: FetchData<ParamsType, DataType>,
    params:ParamsType,
    deps:Array<any>,
): [DataType | null, boolean, Error | null] {
  const isCancelled = useRef<boolean>(false);
  const [data, setData] = useState<DataType | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      isCancelled.current = true;
    }
  }, []);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    setLoading(true);
    isCancelled.current = false;

    fetchData(params, signal)
      .then((data: DataType) => {
        if (isCancelled.current === false) {
          setLoading(false);
          setData(data);
        }
      })
      .catch((error: Error) => {
        if (error.name !== "AbortError") {
          setLoading(false);
          setError(error);
        }
      });

    return () => {
      abortController.abort();
    }
  }, deps);

  return [data, loading, error];
}
