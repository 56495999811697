import get from "./api";


export interface Drug {
    name: string;
}

// Response types
type DrugResponse = string;


function drugResponseToDrug(drugResponse: DrugResponse): Drug {
    return {
        name: drugResponse,
    }
}

interface SearchDrugProps {
    runId: string;
    query: string;
}

export default async function searchDrug (
    {runId, query}: SearchDrugProps,
    signal: AbortSignal | null,
    ): Promise<Array<Drug>> {
    const params = {
        query,
        run_id: runId,
    };
    const response = await get("/drug", params, signal);
    const searchResponse: Array<Drug> = response.drugs.map(drugResponseToDrug);
    return searchResponse;
}
