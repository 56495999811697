type ValueOrUndefined = <T>(value: T) => T | undefined;

export const valueOrUndefined: ValueOrUndefined = (value) => {
    if (value == null) {
        return undefined;
    }
    return value;
};

export const formatRunName = (runId: string): string => {
    const [method, date] = processRunName(runId);
    return `${method} (${date})`;
};

export const processRunName = (runId: string): Array<string> => {
    const [method, date, ...rest] = runId.split("-");
    return [method, date, rest.join("-")];
};

export const asBoolean = (value: string | number): boolean => {
    return value === "true" || value === "t" || value === "1" || value === 1;
};

export const asArray = <T>(value: undefined | null | T | Array<T>): Array<T> => {
    return value ? [value].flat() : [];
};

export function uuid() {
    let uuidValue = "a", k, randomValue;
    for (k = 0; k < 32; k++) {
        randomValue = Math.random() * 16 | 0;

        if (k === 8 || k === 12 || k === 16 || k === 20) {
            uuidValue += "-";
        }
        uuidValue += (k === 12 ? 4 : (k === 16 ? ((randomValue & 3) | 8) : randomValue)).toString(16);
    }
    return uuidValue;
}


// TODO: Name may be misleading because it does not just convert from array to dict, but the dict contains an object with "name" property
export function arrayToDict(array: Array<string>): {[key: string]: {name: string}} {
    return array.reduce((acc: {[key: string]: string}, name: string) => {
        return {
            ...acc,
            [name]: {
                name,
            },
        };
    }, {});
}
