import get from "./api";

export interface MechanismOfAction {
    name: string;
}


// Response types
type MechanismOfActionSearchResponse = string;

interface MechanismsOfActionResponse {
    mechanisms_of_action: Array<MechanismOfActionSearchResponse>;
}

function mechanismOfActionResponseToMechanismOfAction(moaResponse: MechanismOfActionSearchResponse): MechanismOfAction {
    return {
        name: moaResponse,
    }
}

interface SearchMoaProps {
    runId: string;
    query: string;
}

export default async function searchMoa (
    {runId, query}: SearchMoaProps,
    signal: AbortSignal | null,
    ): Promise<Array<MechanismOfAction>> {
    const params = {
        query,
        run_id: runId,
    };
    const response: MechanismsOfActionResponse = await get("/mechanism_of_action", params, signal);
    const moaResponse: Array<MechanismOfAction> = response.mechanisms_of_action.map(
        mechanismOfActionResponseToMechanismOfAction
    );
    return moaResponse;
}
