import get from "./api";

import {valueOrUndefined} from "../utils";
import {SavedCombinationProject, SavedCombinationProjectResponse, savedResponseToSaved} from "./fetchSavedCombinations";
import {Drug} from "./searchDrug";
import {MechanismOfAction} from "./searchMoa";


export interface Run {
    totalRows: number;
    rows: Array<Row>;
}

interface KnownList {
    [list: string]: number;
}

export interface Row {
    saved: SavedCombinationProject | null;
    uid: string;
    drug1: string;
    drug2: string;
    drug1IsSafe: boolean;
    drug1Moa: Array<MechanismOfAction> | null;
    drug2IsSafe: boolean;
    drug2Moa: Array<MechanismOfAction> | null;
    rank: number;
    percentile: number;
    nKnown: KnownList;
    ddi: Array<string>;
    isSafeDrug: boolean;
    score: number;
}


// Response types

interface RunResponse {
    total_rows: number;
    rows: Array<RowResponse>;
}

interface RowResponse {
    is_saved: SavedCombinationProjectResponse | null;
    uid: string;
    drug1: string;
    drug2: string;
    drug1_is_safe: boolean;
    drug1_moa: Array<MechanismOfAction> | null;
    drug2_is_safe: boolean;
    drug2_moa: Array<MechanismOfAction> | null;
    rank: number;
    percentile: number;
    n_known: KnownList;
    DDI: Array<string>;
    safe_drug_count: number;
    score: number;
}


function parseRun(runResponse: RunResponse): Run {
    return {
        totalRows: runResponse.total_rows,
        rows: toRows(runResponse.rows),
    }
}

function toRows(responseRows: Array<RowResponse>): Array<Row> {
    return responseRows.map(reponseRowToRow);
}

function reponseRowToRow(responseRow: RowResponse): Row {
    return {
        saved: responseRow.is_saved ? savedResponseToSaved(responseRow.is_saved) : null,
        uid: responseRow.uid,
        drug1: responseRow.drug1,
        drug2: responseRow.drug2,
        drug1IsSafe: responseRow.drug1_is_safe,
        drug1Moa: responseRow.drug1_moa,
        drug2IsSafe: responseRow.drug2_is_safe,
        drug2Moa: responseRow.drug2_moa,
        rank: responseRow.rank,
        percentile: responseRow.percentile,
        nKnown: responseRow.n_known,
        ddi: responseRow.DDI,
        isSafeDrug: responseRow.safe_drug_count > 0,
        score: responseRow.score,
    };
}


export interface FetchRunProps {
    runId: string;
    projectId: string;
    knownList: string | null;
    pageNumber: number,
    pageSize: number,
    drugsSearch: Array<Drug>;
    drugsExclude: Array<Drug>;
    subsetChoice: string;
    nSafeDrugs: string;
    savedOnly: boolean;
    moas: Array<MechanismOfAction>;
}

export default async function fetchRun(props: FetchRunProps, signal: AbortSignal): Promise<Run> {
    const {
        runId,
        projectId,
        knownList,
        pageNumber,
        pageSize,
        drugsSearch,
        drugsExclude,
        subsetChoice,
        nSafeDrugs,
        savedOnly,
        moas,
    } = props;

    const params = {
        "page_number": pageNumber,
        "page_size": pageSize,
        "subset_choice": subsetChoice,
        "safe_drugs": nSafeDrugs,
        "drug_search": drugsSearch.map(drug => drug.name),
        "drug_exclude": drugsExclude.map(drug => drug.name),
        "project_id": projectId,
        "known_list": valueOrUndefined(knownList),
        "moa": moas.map(moa => moa.name),
    };

    const paramsWithSavedOnly = savedOnly ? {
        ...params,
        "saved_only": 1,
    } : params;

    const response = await get(`/run/${runId}`, paramsWithSavedOnly, signal);
    const runData: Run = parseRun(response);
    return runData;
}
