import get from "./api";

export interface KnownList {
    name: string;
    drugs: Array<string>;
}

interface FetchKnownListForProjectProps {
    projectId: string;
}

export default async function fetchKnownListsForProject(
    {projectId}: FetchKnownListForProjectProps,
    signal: AbortSignal | null,
): Promise<Array<KnownList>> {
    const knownLists: Array<KnownList>  = await get(`/known_lists/${projectId}`, {}, signal);
    return knownLists;
}
