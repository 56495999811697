import get from "./api";


interface UnsaveCombinationProps {
    uid: string;
    projectId: string;
    runId: string;
}

export default async function unsaveCombination({uid, projectId, runId}: UnsaveCombinationProps): Promise<string> {
    const params = {
        run_id: runId,
        uid,
    };

    const response: string = await get(`/project/${projectId}/combination/unsave`, params);
    return response;
}
