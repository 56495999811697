import queryString from "query-string";
import {RouteComponentProps} from "react-router-dom";

interface UrlParamsProps {
    savedOnly?: string;
    projectId?: string;
    compound?: Array<string>;
    exclude?: Array<string>;
    moa?: Array<string>;
}

export default function useUrlParams(
    props: RouteComponentProps<any>
): [UrlParamsProps, (newParams: UrlParamsProps) => void]  {
    const params: UrlParamsProps = queryString.parse(props.location.search);

    const urlParams: UrlParamsProps = {
        savedOnly: params.savedOnly,
        projectId: params.projectId,
        compound: params.compound,
        exclude: params.exclude,
        moa: params.moa,
    };

    const setUrlParams = (newParams: UrlParamsProps) => {
        const mergedParams = {
            ...urlParams,
            ...newParams
        };

        props.history.push({
            search: `?${queryString.stringify(mergedParams)}`,
        })
    };

    return [
        urlParams,
        setUrlParams,
    ];
}
