import React, {ReactNode} from 'react';

import useFetchData from "../useFetchData";
import Spinner from "../Spinner";
import Error from "../Error";
import {FetchData as FetchDataType} from "../useFetchData";

interface FetchDataProps<ParamsType, DataType> {
    endpoint: FetchDataType<ParamsType, DataType>;
    params: ParamsType;
    deps: Array<any>;
    children: (summary: DataType) => ReactNode;
}

export default function FetchData<ParamsType, DataType>(props: FetchDataProps<ParamsType, DataType>) {
    const [data, loading, error] = useFetchData(
        props.endpoint,
        props.params,
        props.deps,
    );

    const [dataResponse, setDataResponse] = React.useState<DataType | null>(null);

    React.useEffect(() => {
        if (data !== null) {
            setDataResponse(data);
        }
    }, [data]);

    if (error !== null) {
        return (
            <Error
                message='Error loading data'
            />
        )
    }

    return (
        <Spinner loading={loading}>
            {dataResponse ?
                props.children(dataResponse) :
                null
            }
        </Spinner>
    );
}
