import {faHeart} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {
    Button,
    Card, CardBody, CardHeader,
    Col,
    Collapse,
    Modal, ModalHeader, ModalBody, ModalFooter,
    Row,
    Tooltip,
} from "reactstrap";

import {KnownList as KnownListType} from "../../api/fetchKnownListsForProject";
import {
    Dataset as DatasetType,
    Project as ProjectType,
    Run as RunType,
} from "../../api/fetchProjects";
import {formatRunName, processRunName} from "../../utils";

import style from "./project.module.scss";

interface ProjectProps {
    project: ProjectType;
}

export default function Project({project}: ProjectProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    return (
        <Card>
            <CardHeader
                onClick={() => setIsOpen(!isOpen)}
                className={style.cardHeader}
            >
                <Link to={`/project/${project.projectId}`}>
                    <SectionTitle section="project id" title={project.projectId}/>
                </Link>
            </CardHeader>
            <Collapse isOpen={isOpen}>
                <CardBody>
                    <Row>
                        {project.datasets.map((dataset) => (
                            <Col key={dataset.datasetId} className="col-xl-6 col-xs-12 mb-3">
                                <Dataset dataset={dataset} projectId={project.projectId}/>
                            </Col>
                        ))}
                    </Row>
                    <Row>
                        <KnownLists knownLists={project.knownLists}/>
                    </Row>
                </CardBody>
            </Collapse>
        </Card>
    );
}

interface DatasetProps {
    projectId: string;
    dataset: DatasetType;
}


function Dataset({dataset, projectId}: DatasetProps) {
    return (
        <Card>
            <CardHeader>
                <SectionTitle section="dataset id" title={dataset.datasetId}/>
            </CardHeader>

            <CardBody>
                {dataset.runs.map(run => (
                    <Run
                        key={run.runId}
                        run={run}
                        projectId={projectId}
                        datasetId={dataset.datasetId}
                    />
                ))}
            </CardBody>
        </Card>
    );
}

interface RunProps {
    projectId: string;
    datasetId: string;
    run: RunType,
}

function Run({run, projectId, datasetId}: RunProps) {
    const [showSavedTooltip, setShowSavedTooltip] = useState<boolean>(false);

    const runUid = processRunName(run.runId)[2];

    const savedCombinations = run.savedCombinations > 0 ? (
        <React.Fragment>
            <Link to={`/run/${run.runId}?projectId=${projectId}&datasetId=${datasetId}&savedOnly=true`}>
                <div
                    id={`run-${runUid}`}
                    className="float-right"
                    onMouseOver={() => setShowSavedTooltip(true)}
                    onMouseOut={() => setShowSavedTooltip(false)}
                >
                    {run.savedCombinations}
                    <FontAwesomeIcon className="text-success ml-1" icon={faHeart}/>
                </div>
            </Link>
            <Tooltip
                isOpen={showSavedTooltip}
                placement="auto"
                target={`run-${runUid}`}
            >
                {run.savedCombinations} saved combinations
            </Tooltip>
        </React.Fragment>
    ) : null;

    return (
        <div>
            <Link
                to={`/run/${run.runId}?projectId=${projectId}&datasetId=${datasetId}`}
            >
                {formatRunName(run.runId)}
            </Link>
            {savedCombinations}
        </div>
    );
}

interface SectionTitleProps {
    section: string;
    title: string;
}

function SectionTitle({section, title}: SectionTitleProps) {
    return (
        <React.Fragment>
            <span className={style.sectionTitle}>{section}</span>{" "}
            <span className={style.title}>{title}</span>
        </React.Fragment>
    );
}

interface KnownListsProps {
    knownLists: Array<KnownListType>;
}

function KnownLists({knownLists}: KnownListsProps) {
    return (
        <div className="ml-3">
            Lists of known compounds for this project:
            {knownLists.map(list => (
                <KnownList key={list.name} knownList={list}/>
            ))}
        </div>
    );
}

interface KnownListProps {
    knownList: KnownListType,
}

function KnownList({knownList}: KnownListProps) {
    const [showList, setShowList] = useState<boolean>(false);

    return (
        <div className="ml-3" key={knownList.name}>
            <Modal isOpen={showList}>
                <ModalHeader>{knownList.name}</ModalHeader>
                <ModalBody>
                    {knownList.drugs.sort().map(drug => (
                        <div key={drug}>
                            {drug}
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={() => setShowList(false)}>Close</Button>
                </ModalFooter>
            </Modal>
            <div onClick={() => setShowList(true)}>
                <Button color="link">{knownList.name}</Button>: {knownList.drugs.length} compounds
            </div>
        </div>
    )
}
