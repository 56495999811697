import get from "./api";

export interface SavedCombinations {
    [uid: string]: SavedCombinationProject;
}

export interface SavedCombinationProject {
    [project: string]: SavedCombinationRun;
}

interface SavedCombinationRun {
    [run: string]: SavedCombination;
}

interface SavedCombination {
    uid: string;
    projectId: string;
    runId: string;
    comment: string;
}


// Response types

interface SavedCombinationsResponse {
    [uid: string]: SavedCombinationProjectResponse;
}

export interface SavedCombinationProjectResponse {
    [project: string]: SavedCombinationRunResponse;
}

interface SavedCombinationRunResponse {
    [run: string]: SavedCombinationResponse;
}

interface SavedCombinationResponse {
    uid: string;
    project_id: string;
    run_id: string;
    comment: string;
}

export function savedResponseToSaved(savedResponse: SavedCombinationProjectResponse): SavedCombinationProject {
    return Object.keys(savedResponse).reduce((saved: SavedCombinationProject, project: string) => {
        return {
            ...saved,
            [project]: savedProjectResponseToSavedProject(savedResponse[project]),
        }
    }, {} as SavedCombinationProject);
}

function savedProjectResponseToSavedProject(savedProjectResponse: SavedCombinationRunResponse): SavedCombinationRun {
    return Object.keys(savedProjectResponse).reduce((saved: SavedCombinationRun, run: string) => {
        return {
            ...saved,
            [run]: {
                uid: savedProjectResponse[run].uid,
                comment: savedProjectResponse[run].comment,
                projectId: savedProjectResponse[run].project_id,
                runId: savedProjectResponse[run].run_id,
            },
        }
    }, {} as SavedCombinationRun);
}

interface FetchSavedCombinationsProps {}

export default async function fetchSavedCombinations(props: FetchSavedCombinationsProps, signal: AbortSignal): Promise<SavedCombinations> {
    const response: SavedCombinationsResponse = await get("/saved_combinations", {}, signal);

    const savedCombinations: SavedCombinations =  Object.keys(response).reduce((saved: any, uid: string) => {
        return {
            ...saved,
            [uid]: savedResponseToSaved(response[uid]),
        };
    }, {});

    return savedCombinations;
}
