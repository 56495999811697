import get from "./api";
import {KnownList} from "./fetchKnownListsForProject";

export interface Project {
    projectId: string;
    datasets: Array<Dataset>;
    knownLists: Array<KnownList>
}

export interface Dataset {
    datasetId: string;
    runs: Array<Run>;
}

export interface Run {
    runId: string;
    savedCombinations: number;
}

// Response types
interface ProjectResponse {
    project_id: string;
    datasets: Array<DatasetResponse>;
    known_lists: Array<KnownListResponse>;
}

interface DatasetResponse {
    dataset_id: string;
    runs: Array<RunResponse>;
}

interface RunResponse {
    run_id: string;
    saved_combinations: number;
}

interface KnownListResponse {
    name: string;
    drugs: Array<string>;
}

const projectResponseToProject = (projectResponse: ProjectResponse): Project => ({
    projectId: projectResponse.project_id,
    datasets: projectResponse.datasets.map(datasetResponseToDataset),
    knownLists: projectResponse.known_lists,
});

const runResponseToRun = (runResponse: RunResponse): Run => ({
    runId: runResponse.run_id,
    savedCombinations: runResponse.saved_combinations,
});

const datasetResponseToDataset = (datasetResponse: DatasetResponse): Dataset => ({
    datasetId: datasetResponse.dataset_id,
    runs: datasetResponse.runs.map(runResponseToRun),
});

interface FetchProjectsProps {}

export default async function fetchProjects(
    props: FetchProjectsProps,
    signal: AbortSignal | null,
): Promise<Array<Project>> {
    const projectsResponse = await get(`/projects`, {}, signal);
    const projects: Array<Project> = projectsResponse.map(projectResponseToProject);
    return projects;
}
