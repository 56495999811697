import {put} from "./api";

interface SaveCombinationProps {
    uid: string;
    projectId: string;
    runId: string;
    comment: string;
}

export default async function saveCombination({uid, projectId, runId, comment}: SaveCombinationProps): Promise<string> {
    const payload = {
        run_id: runId,
        comment,
        uid,
    };

    const saveResponse: string = await put(
        `/project/${projectId}/combination/save`,
        payload,
    );
    return saveResponse;
}
