import queryString from "query-string"

const API_PORT = process.env.REACT_APP_API_PORT;
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const BASE_URL = API_PORT ? `${API_BASE_URL}:${API_PORT}` : API_BASE_URL;


interface AccessTokenResponse {
    access_token: string;
}

async function fetchToken(): Promise<AccessTokenResponse> {
    const response = await fetch(`${BASE_URL}/auth`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD,
        })
    });
    const accessToken: AccessTokenResponse = await response.json();
    return accessToken;
}


export default async function get(
    endpoint: string,
    params = {},
    signal: AbortSignal | null = null,
) {
    const serialisedParams = queryString.stringify(params);

    const serialisedParamsUrl = serialisedParams ? `?${serialisedParams}` : "";

    const token = await fetchToken();

    const result = await fetch(`${BASE_URL}${endpoint}${serialisedParamsUrl}`, {
        method: "GET",
        mode: "cors",
        headers: {
            Authorization: `JWT ${token.access_token}`,
        },
        signal,
    });

    return await result.json();
}

export async function put(
    endpoint: string,
    payload = {},
) {
    const token = await fetchToken();

    const result = await(fetch(`${BASE_URL}${endpoint}`, {
        method: "PUT",
        mode: "cors",
        body: JSON.stringify(payload),
        headers: {
            Authorization: `JWT ${token.access_token}`,
        },
    }));

    return await result.json();
}
