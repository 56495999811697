import {AxisLeft, AxisBottom} from '@vx/axis';
import {Group} from "@vx/group";
import {LegendOrdinal, LegendItem, LegendLabel} from "@vx/legend";
import {ParentSize, ResponsiveProps} from "@vx/responsive";
import {scaleBand, scaleLinear, scaleOrdinal} from "@vx/scale";
import {Circle} from "@vx/shape";
import {withTooltip, Tooltip as TooltipPlot} from "@vx/tooltip";
import * as d3 from "d3";
import sortBy from "lodash.sortby";
import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Tooltip} from "reactstrap";

import {formatRunName, uuid} from "../../utils";
import style from "./scoreSummaryPlot.module.scss";

interface Value {
    score: number;
    runId: string;
    dataset: string;
}

interface Item {
    label: string;
    values: Array<Value>;
    urlParams: string;
}

interface DrugSummaryDotPlotProps {
    items: Array<Item>;

    // withTooltip injected props
    showTooltip: any;
    tooltipData: any;
    tooltipLeft: any;
    tooltipTop: any;
    tooltipOpen: any;
    hideTooltip: any;

}

interface Dot {
    score: number;
    runId: string;
    index: number;
    label: string;
    dataset: string;
    urlParams: string;
}

function ScoreSummaryDotPlot({items, ...props}: DrugSummaryDotPlotProps) {
    const [highlightColumn, setHighlightColumn] = useState<string | null>(null);
    const [hideCategories, setHideCategories] = useState<Set<string>>(new Set());

    const height = 600;
    const margin = {
        top: 20,
        left: 70,
        bottom: 200,
    };

    const healxColor = "#66C1B0";

    const flattenedItems: Array<Dot> = items.map((item, index) => item.values.map(value => ({
        score: value.score,
        runId: value.runId,
        dataset: value.dataset,
        index: index,
        label: item.label,
        urlParams: item.urlParams,
    }))).flat();

    const yScale = scaleLinear({
        domain: d3.extent(flattenedItems, item => item.score),
        range: [height - margin.bottom, margin.top],
    });

    const structureColorKey = (dot: Dot) => `${dot.runId} :::: ${dot.dataset}`;
    const destructureColorKey = (key: string) => {
        return key.split(" :::: ");
    };

    const runIds = Array.from(new Set(flattenedItems.map(structureColorKey)).values());

    const runColorScale = scaleOrdinal({
        domain: runIds,
        range: d3.schemeCategory10,
    });

    const x = (d: Dot) => d.label;
    const y = (d: Dot) => d.score;

    const elementColor = (value: string, category: string | null) => {
        if ((highlightColumn == null) || (value === highlightColumn)) {
            return category == null ?
                healxColor :
                hideCategories.has(category) ?
                    "none" :
                    runColorScale(category);
        }
        return "none";
    };

    const toggleCategory = (category: string) => {
        if (hideCategories.has(category)) {
            hideCategories.delete(category);
            setHideCategories(new Set(hideCategories.values()));
        } else {
            hideCategories.add(category);
            setHideCategories(new Set(hideCategories.values()));
        }
    };

    const dataPointsForItem = (label: string | null) => {
        const item = items.filter(item => item.label === label)[0];
        if (item) {
            return item.values.length;
        }
        return 0;
    };

    return (
        <ParentSize>
            {({width}: ResponsiveProps) => {
                const xScale = scaleBand({
                    domain: items.map(item => item.label),
                    range: [0, width - margin.left],
                });

                return (
                    <React.Fragment>
                        <svg
                            width={width}
                            height={height}
                        >
                            <text
                                x={width / 2}
                                y={10}
                                color={healxColor}
                                style={{
                                    textAnchor: "middle",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    visibility: highlightColumn == null ? "hidden" : "visible",
                                }}
                            >
                                {highlightColumn}{' '}
                                ({dataPointsForItem(highlightColumn)} data points)
                            </text>
                            <Group
                                left={margin.left}
                            >
                                {flattenedItems.map((item, index) => {
                                    const cx = xScale(x(item));
                                    const cy = yScale(y(item));
                                    const r = 3;

                                    return (
                                        <Link
                                            key={`point-${index}`}
                                            to={`/run/${item.runId}?${item.urlParams}&datasetId=${item.dataset}`}
                                        >
                                            <Circle
                                                className="dot"
                                                cx={cx}
                                                cy={cy}
                                                r={r}
                                                fill={elementColor(item.label, structureColorKey(item))}
                                                style={{cursor: "pointer"}}
                                                onMouseEnter={() => {
                                                    props.showTooltip({
                                                        tooltipLeft: cx,
                                                        tooltipTop: cy + 20,
                                                        tooltipData: item,
                                                    })
                                                }}
                                                onMouseOut={() => props.hideTooltip()}
                                            />
                                        </Link>
                                    );
                                })}
                            </Group>
                            <Group
                                left={margin.left}
                            >
                                <AxisLeft
                                    top={0}
                                    left={0}
                                    scale={yScale}
                                    label="Percentile"
                                    labelProps={{
                                        fill: "#000000",
                                        textAnchor: "middle",
                                        fontSize: 15,
                                        fontFamily: "Arial",
                                    }}
                                    stroke="#1b1a1e"
                                    tickLabelProps={() => ({
                                        fill: '#000000',
                                        textAnchor: 'end',
                                        fontSize: 12,
                                        fontFamily: 'Arial',
                                        dx: '-0.25em',
                                        dy: '0.25em'
                                    })}
                                    tickComponent={({formattedValue, ...tickProps}: any) => (
                                        <text {...tickProps}>{formattedValue}</text>
                                    )}
                                />
                            </Group>
                            <Group
                                left={margin.left}
                                top={height}
                            >
                                <AxisBottom
                                    top={-margin.bottom}
                                    left={0}
                                    scale={xScale}
                                    numTicks={items.length}
                                >
                                    {(axis: any) => {
                                        const tickLabelSize = 12;
                                        const tickRotate = -45;
                                        return (
                                            <g>
                                                {axis.ticks.map((tick: any, i: number) => {
                                                    const tickX = tick.to.x - axis.tickLength;
                                                    const tickY = tick.to.y + 10;
                                                    return (
                                                        <Group
                                                            key={`vx-tick-${tick.value}-${i}`}
                                                            className={"vx-axis-tick"}
                                                        >
                                                            <text
                                                                transform={`translate(${tickX}, ${tickY}) rotate(${tickRotate})`}
                                                                fontSize={tickLabelSize}
                                                                textAnchor="end"
                                                                fill={elementColor(tick.value, null)}
                                                                onMouseOver={() => {
                                                                    setHighlightColumn(tick.value)
                                                                }}
                                                                onMouseOut={() => {
                                                                    setHighlightColumn(null);
                                                                }}
                                                                style={{cursor: "default", fontWeight: "bold"}}
                                                            >
                                                                {tick.formattedValue}
                                                            </text>
                                                        </Group>
                                                    )
                                                })}
                                            </g>
                                        );
                                    }}
                                </AxisBottom>
                            </Group>
                        </svg>
                        {props.tooltipOpen && (
                            <TooltipPlot
                                left={props.tooltipLeft}
                                top={props.tooltipTop}
                            >
                                <div>
                                    <div className="my-3">Label: <b>{props.tooltipData.label}</b></div>
                                    <div className="my-3">Dataset: <b>{props.tooltipData.dataset}</b></div>
                                    <div className="my-3">Run: <b>{formatRunName(props.tooltipData.runId)}</b></div>
                                    <div className="my-3">Score: <b>{props.tooltipData.score.toFixed(2)}</b></div>
                                </div>
                            </TooltipPlot>
                        )}
                        <LegendOrdinal
                            domain={runColorScale.domain()}
                            scale={runColorScale}
                            labelFormat={(label: string) => {
                                const [run, dataset] = destructureColorKey(label);
                                return `${formatRunName(run)} -- ${dataset}`;
                            }}
                        >
                            {(labels: any) => {
                                const size = 15;
                                return (
                                    <div className="my-3" style={{
                                        display: "inline-block",
                                        cursor: "pointer",
                                        padding: "10px",
                                        border: "1px solid #666666"
                                    }}>
                                        {labels.map((label: any, index: number) => {
                                            const circleColor = hideCategories.has(label.datum) ?
                                                "#EEEEEE" :
                                                label.value;
                                            return (
                                                <LegendItem
                                                    key={`legend-ordinal-${index}`}
                                                    margin={'0 5px'}
                                                    onClick={() => {
                                                        toggleCategory(label.datum);
                                                    }}
                                                >
                                                    <svg width={size} height={size}>
                                                        <circle
                                                            cx={size / 2}
                                                            cy={size / 2}
                                                            r={size / 2}
                                                            fill={circleColor}
                                                        />
                                                    </svg>
                                                    <LegendLabel
                                                        align={'left'}
                                                        margin={'0 0 0 4px'}
                                                    >
                                                        {label.text}
                                                    </LegendLabel>
                                                </LegendItem>
                                            );
                                        })}
                                    </div>
                                );
                            }}
                        </LegendOrdinal>
                    </React.Fragment>
                )
            }}
        </ParentSize>
    );
}

export default withTooltip(ScoreSummaryDotPlot);

interface ScoreSummaryListProps {
    score: number;
    values: Array<Value>;
    urlParams: string;
}

export function ScoreSummaryList({score, values, urlParams}: ScoreSummaryListProps) {
    const sortedValues = sortBy(values, value => -value.score);

    return (
        <div className="ml-2 mt-1 mb-3">
            <DatasetScore>
                {score.toFixed(2)}
            </DatasetScore>

            <div>
                Percentiles in datasets: {sortedValues.map(value => (
                <RunValue key={value.runId} uid={uuid()} dataset={value.dataset} run={value.runId} score={value.score}>
                    <Link to={`/run/${value.runId}?${urlParams}&datasetId=${value.dataset}`}>{value.score.toFixed(2)}</Link>
                </RunValue>
            ))}
            </div>
        </div>
    );
}


interface DatasetScoreProps {
    children: React.ReactNode;
}

function DatasetScore(props: DatasetScoreProps) {
    return (
        <div>
            Percentile: <b>{props.children}</b>
        </div>
    );
}


interface RunValueProps {
    uid: string;
    dataset: string;
    score: number;
    run: string;
    children: React.ReactNode;
}

function RunValue({uid, dataset, run, score, ...props}: RunValueProps) {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    return (
        <React.Fragment>
            <span
                className="ml-2" id={uid}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <b>{props.children}</b>
            </span>
            <Tooltip
                innerClassName={style.valueTooltip}
                isOpen={showTooltip}
                target={uid}
                placement="auto"
            >
                <div>
                    <div>dataset: <b>{dataset}</b></div>
                    <div>run: <b>{formatRunName(run)}</b></div>
                    <div>score: <b>{score.toFixed(2)}</b></div>
                </div>
            </Tooltip>
        </React.Fragment>
    )
}
