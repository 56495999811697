import get from "./api";

export interface ProjectRunSummary {
    run: string;
    score: number;
}


interface DatasetRunScore extends ProjectRunSummary {
    dataset: string;
}


export interface ProjectCombinationSummary {
    uid: string;
    drugs: Array<string>;
    datasetPercentiles: Array<DatasetRunScore>;
    score: number;
}

interface ProjectDrugDatasetSummary {
    dataset: string;
    runs: Array<ProjectRunSummary>;
    score: number;
}


export interface ProjectDrugSummary {
    drugName: string;
    datasets: Array<ProjectDrugDatasetSummary>;
    score: number;
}


export interface ProjectMoASummary {
    uid: string;
    moas: Array<string>;
    score: number;
    datasets: Array<ProjectDrugDatasetSummary>;
}


export interface FetchProjectSummary {
    drugs: Array<ProjectDrugSummary>;
    combinations: Array<ProjectCombinationSummary>;
    moas: Array<ProjectMoASummary>;
}


// Response interfaces
interface ProjectDrugSummaryResponse {
    drug_name: string;
    datasets: Array<ProjectDrugDatasetSummary>;
    score: number;
}

interface ProjectCombinationSummaryResponse {
    uid: string;
    drugs: Array<string>;
    dataset_percentiles: Array<DatasetRunScore>;
    meta_score: number;
}

interface ProjectMoASummaryResponse {
    uid: Array<string>;
    moas: Array<string>;
    score: number;
    datasets: Array<ProjectDrugDatasetSummary>;
}

interface ProjectSummaryResponse {
    drugs: Array<ProjectDrugSummaryResponse>;
    combinations: Array<ProjectCombinationSummaryResponse>;
    moas: Array<ProjectMoASummaryResponse>;
}

function formatProjectSummary(summaryResponse: ProjectSummaryResponse): FetchProjectSummary {
    const drugs = summaryResponse.drugs.map(drug => ({
        drugName: drug.drug_name,
        datasets: drug.datasets,
        score: drug.score,
    }));
    const combinations = summaryResponse.combinations.map(combination => ({
        uid: combination.uid,
        drugs: combination.drugs,
        datasetPercentiles: combination.dataset_percentiles,
        score: combination.meta_score,
    }));
    const moas = summaryResponse.moas.map(moa => ({
        uid: moa.uid.join("|"),
        moas: moa.moas,
        datasets: moa.datasets,
        score: moa.score,
    }));
    return {
        drugs,
        combinations,
        moas,
    };
}

export interface FetchProjectSummaryParamsProps {
    projectId: string;
}

export default async function fetchProjectSummary(
    {projectId}: FetchProjectSummaryParamsProps,
    signal: AbortSignal
): Promise<FetchProjectSummary> {
    const response: ProjectSummaryResponse = await get(`/project/${projectId}`, {}, signal);
    const summaryResponse: FetchProjectSummary = formatProjectSummary(response);
    return summaryResponse;
}
