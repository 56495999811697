import {faShieldAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {GoogleLogout, GoogleUser} from "react-google-login";
import {NavLink} from "react-router-dom";
import {Nav, Navbar, NavbarBrand, NavItem} from "reactstrap";

import Logo from "../Logo";
import style from "./masthead.module.scss";

interface MastheadProps {
    user: GoogleUser | null;
    onLogoutSuccess: () => void;
}

const Masthead = ({user, onLogoutSuccess}: MastheadProps) => {
    const loggedInUser = user != null ? (
        <React.Fragment>
            <div className="my-auto">
                <FontAwesomeIcon icon={faShieldAlt}/>{" "}
                {user.profileObj.name}
            </div>
            <GoogleLogout
                className="ml-3"
                clientId={user.googleId}
                buttonText="Logout"
                onLogoutSuccess={onLogoutSuccess}
            />
        </React.Fragment>
    ) : null;

    return (
        <Navbar className={style.masthead}>
            <NavbarBrand>
                <Logo/>
                <h1>Combinations UI</h1>
            </NavbarBrand>
            <Nav className="ml-auto">
                <NavItem className={style.navItem}>
                    <NavLink to="/">
                        Projects
                    </NavLink>
                </NavItem>
                <NavItem className={style.navItem}>
                    <NavLink to="/saved">
                        Saved combinations
                    </NavLink>
                </NavItem>
            </Nav>
            <Nav className="ml-auto">
                {loggedInUser}
            </Nav>
        </Navbar>
    );
};

export default Masthead;
