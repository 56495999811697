import React from "react";
import {Container} from "reactstrap";

import fetchProjects, {Project as ProjectProps} from "../../api/fetchProjects";
import FetchData from "../../components/FetchData";
import Project from "../../components/Project";


function Home() {
    return (
        <Container className="mt-4">
            <h1>Projects</h1>
            <FetchData
                endpoint={fetchProjects}
                params={{}}
                deps={[]}
            >
                {(projects: Array<ProjectProps>) => projects.map(project => (
                    <div className="my-4">
                        <Project key={project.projectId} project={project}/>
                    </div>
                ))}
            </FetchData>
        </Container>
    );
}

export default Home;
