/* tslint:disable:max-line-length */

import React from "react";
import style from "./logo.module.scss";

function Logo() {
    return (
        <svg
            role="img"
            aria-label="Healx"
            className={style.logo}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="3616 -2770 114.215 36.043"
            width="300"
        >
            <title>Healx</title>
            <g id="Group_86" data-name="Group 86" transform="translate(3616 -2770)">
                <g id="Group_78" data-name="Group 78">
                    <path
                        id="Path_119"
                        data-name="Path 119"
                        className="cls-1"
                        d="M3.7 33.521a2.5 2.5 0 0 1-.325 1.627 1.822 1.822 0 0 1-1.505.814 1.728 1.728 0 0 1-1.505-.814A2.252 2.252 0 0 1 0 33.521V2.441A2.429 2.429 0 0 1 .366.773 1.687 1.687 0 0 1 1.871 0a1.737 1.737 0 0 1 1.505.773A2.7 2.7 0 0 1 3.7 2.441v11.431a8.882 8.882 0 0 1 6.875-3.01c5.614 0 8.909 3.58 8.909 9.112v13.547a2.5 2.5 0 0 1-.325 1.627 1.822 1.822 0 0 1-1.505.814 1.728 1.728 0 0 1-1.505-.814 2.471 2.471 0 0 1-.366-1.627V20.544c0-4.312-2.237-6.428-5.98-6.428-3.783 0-6.1 2.156-6.1 6.428v12.977z"
                    />
                </g>
                <g id="Group_79" data-name="Group 79" transform="translate(26.524 10.902)">
                    <path
                        id="Path_120"
                        data-name="Path 120"
                        className="cls-1"
                        d="M68.9 40.265c0 5.37 2.522 8.543 7.282 8.543a8.093 8.093 0 0 0 5.248-1.749c.773-.651 1.139-.895 1.668-.895a1.637 1.637 0 0 1 1.546 1.587c0 1.1-1.546 2.2-2.563 2.807a12.153 12.153 0 0 1-6.143 1.383c-6.468 0-10.74-3.865-10.74-12.57 0-8.1 3.987-12.57 10.129-12.57 6.224 0 10.007 4.556 10.089 11.391a2.23 2.23 0 0 1-.488 1.668 1.981 1.981 0 0 1-1.627.447H68.9zm12.979-2.725a10.868 10.868 0 0 0-.773-3.987 6.295 6.295 0 0 0-11.472 0 10.884 10.884 0 0 0-.734 3.987z"
                        transform="translate(-65.2 -26.8)"
                    />
                </g>
                <g id="Group_80" data-name="Group 80" transform="translate(51.419 10.862)">
                    <path
                        id="Path_121"
                        data-name="Path 121"
                        className="cls-1"
                        d="M134.819 37.48h7.4v-1.993c0-3.743-1.709-5.7-5.98-5.7a7.3 7.3 0 0 0-5.573 2.2 2.223 2.223 0 0 1-1.505.651 1.6 1.6 0 0 1-1.587-1.587c0-.895.895-1.79 2.4-2.726a10.945 10.945 0 0 1 6.305-1.627c6.346 0 9.641 2.766 9.641 8.177v14.443a2.5 2.5 0 0 1-.325 1.627 1.766 1.766 0 0 1-1.505.814 1.728 1.728 0 0 1-1.505-.814c-.285-.447-.285-.732-.285-1.627v-.041c-2.034 1.953-3.824 2.6-7.038 2.6-3.377 0-5.411-.651-7-2.237a7.191 7.191 0 0 1-1.862-5.04c-.084-4.313 3.049-7.12 8.419-7.12zm.692 11.309c2.034 0 3.946-.163 5.451-1.627.814-.814 1.261-2.115 1.261-4.231v-2.684h-7.038c-3.5 0-5.329 1.546-5.329 4.353s1.871 4.19 5.654 4.19z"
                        transform="translate(-126.396 -26.7)"
                    />
                </g>
                <g id="Group_81" data-name="Group 81" transform="translate(78.269)">
                    <path
                        id="Path_122"
                        data-name="Path 122"
                        className="cls-1"
                        d="M196.021 29.046c0 2.237 1.017 3.539 3.254 3.539a2.952 2.952 0 0 1 1.587.285 1.489 1.489 0 0 1 .692 1.261 1.508 1.508 0 0 1-.692 1.3 2.953 2.953 0 0 1-1.587.285h-.651c-4.19 0-6.224-2.97-6.224-6.59V2.441c0-.895 0-1.18.326-1.668A1.687 1.687 0 0 1 194.231 0a1.79 1.79 0 0 1 1.505.773 2.369 2.369 0 0 1 .366 1.668v26.605z"
                        transform="translate(-192.4)"
                    />
                </g>
                <g id="Group_82" data-name="Group 82" transform="translate(104.891 25.238)">
                    <path
                        id="Path_123"
                        data-name="Path 123"
                        className="cls-1"
                        d="M265.471 72.763a1.711 1.711 0 0 1-1.343-.651l-5.9-7.282a1.706 1.706 0 1 1 2.644-2.156l5.9 7.282a1.692 1.692 0 0 1-.244 2.4 1.577 1.577 0 0 1-1.057.407z"
                        transform="translate(-257.84 -62.04)"
                    />
                </g>
                <g id="Group_83" data-name="Group 83" transform="translate(93.297 10.959)">
                    <path
                        id="Path_124"
                        data-name="Path 124"
                        className="cls-1"
                        d="M237.012 37.7a1.71 1.71 0 0 1-1.342-.651l-5.94-7.319a1.706 1.706 0 1 1 2.644-2.156l5.939 7.322a1.692 1.692 0 0 1-.244 2.4 1.506 1.506 0 0 1-1.057.404z"
                        transform="translate(-229.34 -26.94)"
                    />
                </g>
                <g id="Group_84" data-name="Group 84" transform="translate(104.891 11)">
                    <path
                        id="Path_125"
                        data-name="Path 125"
                        className="cls-1"
                        d="M259.532 37.682a1.672 1.672 0 0 1-1.058-.366 1.692 1.692 0 0 1-.244-2.4l5.9-7.241a1.706 1.706 0 1 1 2.644 2.156l-5.9 7.241a1.814 1.814 0 0 1-1.342.61z"
                        transform="translate(-257.84 -27.04)"
                    />
                </g>
                <g id="Group_85" data-name="Group 85" transform="translate(93.297 25.197)">
                    <path
                        id="Path_126"
                        data-name="Path 126"
                        className="cls-1"
                        d="M231.032 72.7a1.672 1.672 0 0 1-1.058-.366 1.692 1.692 0 0 1-.244-2.4l5.98-7.363a1.706 1.706 0 1 1 2.644 2.156l-5.98 7.363a1.8 1.8 0 0 1-1.342.61z"
                        transform="translate(-229.34 -61.94)"
                    />
                </g>
            </g>
        </svg>
    );
}

export default Logo;
